<template>
  <div class="set">
    <van-nav-bar
      title="设置"
      left-text="返回"
      left-arrow
      @click-left="click_left"
    />
    <van-cell-group class="mt-10">
      <van-cell title="账号Id" value="8888888" />
      <van-cell title="登录密码" is-link value="未设置" url="#" />
      <van-cell title="安全密码" is-link value="未设置" to="index" />
      <van-cell
        title="清除缓存"
        is-link
        @click="clearCache"
        value="未设置"
        to="#"
      />
    </van-cell-group>

    <van-button
      class="BottomPage"
      style="buttom: 0px"
      type="default"
      @click="logout"
      block
      >退出登录</van-button
    >
  </div>
</template>
<style lang="less" scoped>
.van-nav-bar /deep/ .van-icon,
.van-nav-bar /deep/.van-nav-bar__text {
  color: #c03131;
}
.set {
  min-height: 100vh;
  background: #f4f4f4;
}

.BottomPage {
  position: fixed;
  bottom: 0px;
  height: 50px;
  font-size: 16px;
  color: #c03131;
}
</style>
<script>
import $http from "@/utils/http";
export default {
  methods: {
    click_left() {
      this.$router.go(-1);
    },
    logout() {
      $http.post("/user/logout").then(() => {
        window.sessionStorage.clear();
        this.$store.commit("set_phone", "");
        this.$store.commit("set_token", "");
        this.$router.push("/my");
      });
    },
    clearCache() {
      localStorage.clear();
      this.$toast("清除成功");
    },
  },
};
</script>
